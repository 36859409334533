/** @jsxRuntime classic */
/** @jsx jsx */
// @ts-check
import { navigate } from 'gatsby';
import { useEffect } from 'react';

export default function ProductRedirect(props) {
  const { exhibitorId } = props.params;
  useEffect(() => {
    navigate(`/partners/${exhibitorId}?utm_source=qrcode`);
  });
  return null;
}
